/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 项目
 */
import calendarsearchProducts from "./calendarsearchProducts";
import searchDistributionProducts from "./searchDistributionProducts";
import getProductFlashSale from "./getProductFlashSale";
import getFlashesByProductIdByActivityId from "./getFlashesByProductIdByActivityId";
import searchFloorProducts from "./searchFloorProducts";
import getCalendar from "./getCalendar";
import getHotSaleProduct from "./getHotSaleProduct";
import getProductRemind from "./getProductRemind";
import getShowMonthProduct from "./getShowMonthProduct";
import getServicePopupInfo, {
  GetServicePopupInfoParams,
} from "./getServicePopupInfo";
import captchaType from "./captchaType";
import getProjectInfoByProjectId, {
  GetProjectInfoByProjectIdParams,
} from "./getProjectInfoByProjectId";
import getProjectInfo, { GetProjectInfoParams } from "./getProjectInfo";
import queryProducts from "./queryProducts";
import queryTours from "./queryTours";
import getRecommendList, { GetRecommendListParams } from "./getRecommendList";
import searchProducts from "./searchProducts";
import searchProductsData from "./searchProductsData";
import getSubjectProducts from "./getSubjectProducts";
import searchTours from "./searchTours";

export class ProductApi {
  constructor(
    public calendarsearchProducts: (
      condition: good.CategoryCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.RecommendProductDtoMap;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchDistributionProducts: (
      condition: good.DistributionCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProductFlashSale: (
      productId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.FlashSaleDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getFlashesByProductIdByActivityId: (
      activityId: string,
      productId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.FlashSaleDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchFloorProducts: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.FloorProductsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCalendar: (
      condition: good.CalendarCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.BaiduAlmanacInfoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getHotSaleProduct: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.HotSaleProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProductRemind: (
      productId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.ProductRemindDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getShowMonthProduct: (
      month: number,
      year: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.Holidays>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getServicePopupInfo: (
      productId: number,

      params: GetServicePopupInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.ServicePopupDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public captchaType: (
      productId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProjectInfoByProjectId: (
      projectId: string,

      params: GetProjectInfoByProjectIdParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.ProjectDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProjectInfo: (
      productId: number,

      params: GetProjectInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.ProjectDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public queryProducts: (
      searchProductRequest: good.SearchProductRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public queryTours: (
      searchTourRequest: good.SearchTourRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getRecommendList: (
      params: GetRecommendListParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchProducts: (
      condition: good.CategoryCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchProductsData: (
      condition: good.CategoryCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSubjectProducts: (
      productsCondition: good.SubjectProductsCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchTours: (
      condition: good.CategoryCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  calendarsearchProducts,
  searchDistributionProducts,
  getProductFlashSale,
  getFlashesByProductIdByActivityId,
  searchFloorProducts,
  getCalendar,
  getHotSaleProduct,
  getProductRemind,
  getShowMonthProduct,
  getServicePopupInfo,
  captchaType,
  getProjectInfoByProjectId,
  getProjectInfo,
  queryProducts,
  queryTours,
  getRecommendList,
  searchProducts,
  searchProductsData,
  getSubjectProducts,
  searchTours,
} as ProductApi;
