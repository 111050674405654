/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 演出字典接口
 */
import businessPartner from "./businessPartner";
import aliyunCaptchaType from "./aliyunCaptchaType";
import verifyCaptcha from "./verifyCaptcha";
import cloudPayEndpoint from "./cloudPayEndpoint";
import codeBook from "./codeBook";
import searchCategories from "./searchCategories";
import shareEndpoints from "./shareEndpoints";
import searchShopCategories from "./searchShopCategories";
import switchEndpoints from "./switchEndpoints";

export class PerformanceApi {
  constructor(
    public businessPartner: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.BussinessParentDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public aliyunCaptchaType: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: dictionary.AliyunCaptchaType;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public verifyCaptcha: (
      captchaRequest: dictionary.CaptchaRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cloudPayEndpoint: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: dictionary.CloudPayEndpoint;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public codeBook: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: ObjectMap<string, Array<dictionary.Codebook>>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchCategories: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.ProductCategoryDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shareEndpoints: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.ShareEndpoint>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchShopCategories: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.ProductCategoryDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public switchEndpoints: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: dictionary.SwitchEndpointDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  businessPartner,
  aliyunCaptchaType,
  verifyCaptcha,
  cloudPayEndpoint,
  codeBook,
  searchCategories,
  shareEndpoints,
  searchShopCategories,
  switchEndpoints,
} as PerformanceApi;
